<template>
	<div class="payment-detail pa-3">
		<v-simple-table
			class="mt-3"
		>
			<caption
				class="text-left pl-0 font-weight-bold"
			><span>결제정보</span></caption>
			
			<colgroup>
				<col width="100px" />
				<col widht="auto" />
			</colgroup>
			<tbody>
				<tr>
					<th>상품명</th>
					<td>{{ item.productNm }}</td>
				</tr>
				<tr>
					<th>판매금액</th>
					<td>{{ item.totAmt |makeComma }}</td>
				</tr>
				<tr>
					<th>연락처</th>
					<td>{{ item.userPhone1 | makePhoneNumber }}</td>
				</tr>
			</tbody>
		</v-simple-table>
		
		
		<v-simple-table
			class="mt-5"
		>
			<caption
				class="text-left pl-0 font-weight-bold"
			><span>결제내역</span></caption>
			<colgroup>
				<col width="100px" />
				<col widht="auto" />
			</colgroup>
			<tbody>
				
				<tr>
					<th>결제상태</th>
					<td>{{ item.tranStatus | transStatus}}</td>
				</tr>
				<tr>
					<th>카드번호</th>
					<td>{{ item.cardNum | makeCardNumber}}</td>
				</tr>
				<tr>
					<th>승인번호</th>
					<td>{{ item.appNo }}</td>
				</tr>
				<tr
					v-if="false"
				>
					<th>카드종류</th>
					<td>{{ item.cardCate }}</td>
				</tr>
				<tr>
					<th>할부개월</th>
					<td>{{ item.installment ? item.installment : '일시불' }}</td>
				</tr>
				<tr>
					<th>거래일시</th>
					<td>{{ item.tranDt | transDate}} {{ item.tranTm | transTime }}</td>
				</tr>
				<tr>
					<th>공급가</th>
					<td>{{ item.taxAmt | makeComma}}원</td>
				</tr>
				<tr>
					<th>부가세</th>
					<td>{{ item.vatAmt | makeComma}}원</td>
				</tr>
			</tbody>
			<tfoot>
				<tr>
					<th>총 결제금액</th>
					<td>{{ item.totAmt | makeComma}}원</td>
				</tr>
			</tfoot>
		</v-simple-table>
		
		
		<v-simple-table
			class="mt-5"
		>
			<caption
				class="text-left pl-0 font-weight-bold"
			><span>이용상세정보</span></caption>
			<colgroup>
				<col width="100px" />
				<col widht="auto" />
			</colgroup>
			<tbody>
				<tr>
					<th>상점명</th>
					<td>{{ item.compNm }}</td>
				</tr>
				<tr>
					<th>사업자번호</th>
					<td>{{ item.bizNo }}</td>
				</tr>
				<tr>
					<th>대표자명</th>
					<td>{{ item.compCeoNm }}</td>
				</tr>
				<tr>
					<th>주소</th>
					<td>{{ item.compAddr1 }} {{ item.compAddr2 }}</td>
				</tr>
			</tbody>
		</v-simple-table>
		
		<v-row
			class="mt-10"
		>
			<v-col>
				<v-btn
					block
					color="success"
					@click="$router.go(-1)"
				>
					확인
				</v-btn>
			</v-col>
			<v-col
					v-if="viewCancel"
			>
				<v-btn
					block
					outlined
					color="orange"
					@click="confirm"
				>
					카드취소요청
				</v-btn>
			</v-col>
		</v-row>
		
		<Receipt
			:item="item"
			:dialog="dialog"
			
			@sendReceipt="sendReceipt"
			@closeReceipt="closeReceipt"
		>
		</Receipt>

	</div>
</template>

<script>
	import Receipt from '@/views/Payment/Receipt'
	export default{
		name: 'PaymentRegist'
		,components: {Receipt}
		,created: function(){
			this.$emit('setProgram', this.program, this.options)
			this.getData(this.$route.params.item_id)
		}
		,props: ['callBack']
		,data: function(){
			return {
				program: {
					title: '결제 내역'
					,not_navigation: true
					,next: {
						title: '영수증'
						,do: true
						,callBack: 'viewReceipt'
					}
				}
				,sprint: function(val, len){
					return ('0' + (val)).slice("-" + len)
				}
				,dialog: false
				,item: {
					
				}
				,transStats: [
					{
						code: '00'
						,codeName: '승인'
					}
					,{
						code: '05'
						,codeName: '취소 요청'
					}
					,{
						code: '10'
						,codeName: '당일 취소'
					}
					,{
						code: '20'
						,codeName: '매입 취소'
					}
					,{
						code: '90'
						,codeName: '망상 취소'
					}
				]
				,interestFree: [
					{
						text: '선택하세요'
						,value: ''
					}
					,{
						text: '무이자'
						,value: '0'
					}
					,{
						text: '할부 이자'
						,value: '1'
					}
				]
				,installment: [
					{
						text: '선택하세요'
						,value: ''
					}
					,{
						text: '일시불'
						,value: '00'
					}
					,{
						text: '3개월'
						,value: '03'
					}
					,{
						text: '4개월'
						,value: '04'
					}
					,{
						text: '5개월'
						,value: '05'
					}
					,{
						text: '6개월'
						,value: '06'
					}
					,{
						text: '10개월'
						,value: '10'
					}
					,{
						text: '12개월'
						,value: '12'
					}
				]
				,codes: {
					taxFlag: [
						{
							code: '00'
							,codeName: '과세'
						}
						,{
							code: '01'
							,codeName: '비과세'
						}
					]
				}
			}
		}
		,filters: {
			transDate: function(val){
				if(val){
					return val.substring(0, 4) + '.' + val.substring(4, 6) + '.' + val.substring(6, 8)
				}else{
					return ''
				}
			}
			,transTime: function(val){
				if(val){
					return val.substring(0, 2) + ':' + val.substring(2, 4) + ':' + val.substring(4, 6)
				}else{
					return ''
				}
			}
			,transStatus: function(val){
				
				const transStats = 
					{
						'00': '승인'
						,'05': '취소 요청'
						,'10': '당일 취소'
						,'20': '매입 취소'
						,'90': '망상 취소'
					}
				if(val){
					return transStats[val]
				}else{
					return ''
				}
			}
		}
		,computed: {
			tranSeqTo: function(){
				if(!this.item.tranSeq){
					return ''
				}
				return this.item.tranSeq / Math.pow(10, (this.item.tranSeq.length - 1))
			}
			,viewCancel: function(){
				let now = new Date()
				let date = now.getFullYear() + this.sprint(now.getMonth() + 1, 2) + this.sprint(now.getDate(), 2)
				console.log(date + ' : ' + this.item.tranDt)
				if(this.item.tranStatus == '00' && this.item.tranDt == date){
					return true
				}else{
					return false
				}
			}
		}
		,methods: {
			getData: function(tranSeq){
				
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/merch/transaction/' + tranSeq
					,authorize: true
					,callBack: 'setItem'
				})
			}
			,axios: function(config){
				this.$emit('axios', config)
			}
			,setItem: function(call){
				this.item =	call.items.content;
			}
			,confirm: function(){
				this.$emit('modal', {
					title: '카드취소요청'
					,callBack: {
						name: '카드취소'
						,color: 'success'
						,do: 'cancel'
					}
					,contents: '카드결제내역을 취소요청하시겠습니까?'
					,contents_type: 'text'
					,accept: '닫기'
					,icon: 'mdi-check-bold'
					,icon_color: ''
				})
			}
			,cancel: function(){
				this.$emit('axios', {
					request_type: 'post'
					,request_url: '/pay/cancel'
					,request_params: {
						tranSeq: this.item.tranSeq
					}
					,authorize: true
					,callBack: 'result'
				})
			}
			,result: function(call){
				if(call.result){
					this.$emit('modal', {
						title: '카드취소 요청 완료'
						,contents: {
							'결제금액' : this.item.totAmt
							,'결제일시' : this.item.tranDt
//							,'승인번호' : this.item.orderSeq
							,'할부기간' : this.item.payment_type
							,'구매상품' : this.item.productNm
							,'거래번호' : this.item.tranSeq
						}
						,contents_type: 'table'
						,accept: '확인'
						,icon: 'mdi-check-bold'
						,icon_color: 'success'
					})
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
				console.log('pay detail result')
				console.log(call)
				
			}
			,viewReceipt: function(){
				this.dialog = true
			}
			,closeReceipt: function(){
				this.dialog = false
			}
			,sendReceipt: function(){
				console.log('payment receipt sendReceipt')
				this.$emit('axios', {
					request_type: 'post'
					,request_url: ''
					,request_params: {
					}
					,callBack: 'sendReceiptResult'
					,dev: 'success'
				})
			}
			,sendReceiptResult: function(call){
				if(call.result){
					/*this.$emit('alertMsg', 'success', '영수증 전송이 완료되었습니다')*/
          this.$emit('alertMsg', 'error', '해당 서비스는 개발중입니다.')
					this.closeReceipt()
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					console.log('watch : ' + call.name)
					if(call.name == 'cancel'){
						this.cancel(call)
					}else if(call.name == 'result'){						
						this.result(call)
					}else if(call.name == 'setItem'){
						this.setItem(call)
					}else if(call.name == 'viewReceipt'){
						this.viewReceipt()
					}else if(call.name == 'sendReceiptResult'){
						this.sendReceiptResult(call)
					}
				}
			}
		}
	}
</script>

<style>
	.payment-detail caption { border-bottom: 1px solid #ddd; padding: 4px;}
	.payment-detail caption span {border-bottom: 2px solid #555; display: inlin-block; padding: 0 10px 6px;}
	.payment-detail th {}
	.payment-detail td { text-align: right;}
	.payment-detail table {border-bottom: 1px solid #ddd;}
	.payment-detail tfoot th
	, .payment-detail tfoot td	{border-top: 1px solid #ddd; background: #eee;}
	.payment-detail td {font-size: 0.9em !important;}
</style>